'use client'

import React, { ReactNode } from 'react'
import { usePathname } from 'next/navigation'
import Link from 'next/link'
import commonIcons, { SingleRightArrowIcon } from '../icons/common-icons'
import { getIcon } from '@/lib/get-icon'

type BreadCrumbProps = {
    //separator: ReactNode,
    containerClasses?: string,
    listClasses?: string,
    activeClasses?: string,
    capitalizeLinks?: boolean
}

const Breadcrumb = ({ capitalizeLinks }: BreadCrumbProps) => {

    const paths = usePathname()
    const pathNames = paths.split('/').filter(path => path)
    const separator = <SingleRightArrowIcon height={"20px"} width={"20px"} />
    const listClasses = ""
    const activeClasses = "text-amber-500"

    return (
        <>
            {paths !== "/" ?
                <div className={`${pathNames?.[0] === 'pwa' ? "hidden" : ""} absolute w-full left-2/4 -translate-x-2/4 xs:hidden px-11 xs:px-5 sm:px-5 mx-auto z-10`}>
                    <ul className="flex py-9 xs:py-5 sm:pt-5 text-lg gap-0.5 items-center">
                        <li className={listClasses}><Link href={'/'}>{getIcon({ iconList: commonIcons, iconName: "HomeIcon" })}</Link></li>
                        {pathNames.length > 0 && separator}
                        {
                            pathNames.map((link, index) => {
                                let href = `/${pathNames.slice(0, index + 1).join('/')}`
                                let itemClasses = paths === href ? `${listClasses} ${activeClasses}` : listClasses
                                let itemLink = capitalizeLinks ? link[0].toUpperCase() + link.slice(1, link.length) : link
                                return (
                                    <React.Fragment key={index}>
                                        <li className={itemClasses} >
                                            <Link href={href}>{itemLink}</Link>
                                        </li>
                                        {pathNames.length !== index + 1 && separator}
                                    </React.Fragment>
                                )
                            })
                        }
                    </ul>
                </div>
                : ""}

        </>

    )
}

export default Breadcrumb
